import React from 'react'

import Layout from 'components/Layout'

import Hero from 'components/Sections/HeroCustom'

import { Benefits, Contact, FAQs } from 'components/Sections'

const Page = () => (
  <Layout
    showHeader={false}
    seo={{
      meta: [
        {
          name: 'robots',
          content: 'noindex',
        },
      ],
    }}
  >
    <div className="text-r2o-700">
      <Hero />
      <Benefits />
      <FAQs />
      <Contact />
    </div>
  </Layout>
)

export default Page
